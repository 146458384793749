import * as React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import Seo from "../components/seo"
import ProductIcon from "../components/productIcon"

const IndexPage = () => {

    return < Layout >
        <section class="bg-white">
            <div
                class="mx-auto max-w-screen-xl px-4 py-32 my-16 lg:flex lg:h-max lg:items-center"
            >
                <div class="mx-auto max-w-2xl text-center">
                    <h1 class="text-3xl font-extrabold sm:text-5xl">
                        History
                    </h1>

                    <p class="mt-4 sm:text-xl sm:leading-relaxed">
                        We are super new! So, we don't have history yet. But, you can see how we are going to change history by staying posted on everything Irishman Cloud related via our <a class="text-primary hover:underline" href="https://twitter.com/IrishmanCloud">Twitter account</a>.
                    </p>
                </div>
            </div>
        </section>
    </Layout >
}

export const Head = () => <Seo title="History" />

export default IndexPage
